import $ from 'jquery';

import { Script } from '@app/core';

export class LegacyInitScript extends Script {
  private legacyClass: any;

  /**
   * Constructor to allow pass legacy class.
   *
   * @param legacyClass Legacy class with initialize.
   */
  public constructor(legacyClass: any) {
    super();

    this.legacyClass = legacyClass;
  }

  /**
   * Initialize legacy scripts after jquery loaded.
   *
   * @memberof LegacyInitScript
   */
  public execute() {
    $(() => this.legacyClass.initialize());
  }
}
