import { Router } from './core';
import { routes } from './routings';

export class App {
  /**
   * Bootstrap application.
   *
   * @static
   * @returns
   * @memberof App
   */
  public static bootstrap() {
    return new App();
  }

  /**
   * Creates an instance of App.
   *
   * @memberof App
   */
  constructor() {
    const router = new Router(routes);
    router.applyPlugins({ bootstrap: true });
  }
}
