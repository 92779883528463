import { Script } from '@app/core';

import 'cookieconsent';

export class CookieConsentScript extends Script {
  /**
   * Apply TinyMCE after loaded.
   *
   * @memberof CookieConsentScript
   */
  public execute() {
    (<any>window).cookieconsent.initialise({
      container: document.getElementById('content'),
      cookie: {
        name: '__Secure-cookieconsent_status',
        secure: true,
      },
      content: {
        header: django.gettext('Cookies used on the website!'),
        message: django.gettext('This website uses cookies to ensure you get the best experience on our website.'),
        dismiss: django.gettext('Accept'),
        allow: django.gettext('Allow cookies'),
        deny: django.gettext('Decline'),
        link: django.gettext('Learn more'),
      },
      elements: {
        message: '<span id="cookieconsent:desc" class="cc-message pure-cookie-msg">{{message}}</span>',
        dismiss:
          '<a aria-label="dismiss cookie message" tabindex="0" class="cc-btn cc-dismiss pure-button pure-button-primary">{{dismiss}}</a>',
      },
      palette: {
        popup: { background: '#ffffff' },
      },
      showLink: false,
    });
  }
}
