import { Script } from './script';

/**
 * Base Plugin class helper.
 *
 * @export
 * @abstract
 * @class Plugin
 */
export abstract class Plugin extends Script {
  /**
   * Selector to apply this plugin.
   *
   * @protected
   * @type {string}
   * @memberof Plugin
   */
  protected selector: string;

  /**
   * Creates an instance of Plugin.
   *
   * @param {string} selectr CSS selector to apply this plugin.
   * @memberof Plugin
   */
  public constructor(selector: string) {
    super();

    this.selector = selector;
  }

  /**
   * Main method to apply plugin to all elements.
   *
   * @memberof Plugin
   */
  public execute(rootElement: ParentNode): void {
    rootElement.querySelectorAll(this.selector).forEach((element: Element) => this.applyToElement(element));
  }

  /**
   * Redefine this method with specific plugin logic.
   *
   * @protected
   * @abstract
   * @param {Element} element DOM Element to apply.
   * @memberof Plugin
   */
  protected abstract applyToElement(element: Element): void;
}
