import { Routes } from '@app/core';
import { DjangoMessagesPlugin, GoogleMapsPlugin, LaddaPlugin, RecaptchaPlugin } from '@app/plugins';
import {
  CommonMainScript,
  CookieConsentScript,
  FontLoaderScript,
  LegacyInitScript,
  ManagementMainScript,
  TinyMCEScript,
} from '@app/scripts';
import {
  CRIPTO,
  VOTE_AUTH,
  VOTING,
  PW_HELP,
  SECRETS_SHARING,
  ACCESSIBILITY,
  DROPDOWNS,
  ACCORDION,
  DATETIMEPICKER,
} from './scripts/legacy';

/**
 * @description
 *
 * Represents router configuration.
 *
 * `Routes` is an array of route configurations. Each one has the following properties:
 *
 * - `match` define regex matching rule. If not defined, will always execute the view.
 * - `plugins` is the plugins to be applied.
 *
 * Example:
 * ```typescript
 * [
 *   { plugins: [DjangoMessagesPlugin()] },
 *   { match: /homepage/, plugins: [HomepagePlugin()] },
 *   { match: /accounts_*./, plugins: [AccountsPlugin()] },
 * ]
 * ```
 */
export const routes: Routes = [
  {
    plugins: [
      new LegacyInitScript(ACCESSIBILITY),
      new LegacyInitScript(DROPDOWNS),
      new LegacyInitScript(ACCORDION),
      new LegacyInitScript(DATETIMEPICKER),
      new CommonMainScript(),
      new FontLoaderScript(),
      new CookieConsentScript(),
      new DjangoMessagesPlugin('[data-django-messages-plugin]'),
      new LaddaPlugin('[type="submit"]:not([formtarget="_blank"])'),
      new GoogleMapsPlugin('#contacts-map'),
    ],
  },
  {
    match: /(voters-electoralprocess-voting|voters-election-voting-.*|management-election-detail-bulletin-preview)/,
    plugins: [
      // TODO: import evotum cripto third parti package.
      new LegacyInitScript(PW_HELP),
      new LegacyInitScript(VOTING),
      new LegacyInitScript(VOTE_AUTH),
      new LegacyInitScript(CRIPTO),
    ],
  },
  {
    match: /management-.*/,
    plugins: [new ManagementMainScript()],
  },
  {
    match: /management-electoralprocess-generate/,
    plugins: [
      // TODO: import evotum cripto third parti package.
      new LegacyInitScript(SECRETS_SHARING),
    ],
  },
  {
    match: /evoteadmin-page-update/,
    plugins: [new TinyMCEScript()],
  },
  {
    match: /voters-contacts|voters-election-claim/,
    plugins: [new RecaptchaPlugin('.g-recaptcha')],
  },
];
