import './polyfills';

import '@styles/main.scss';

// external dependencies
import 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/effect';
import 'jquery-ui/ui/effects/effect-fade';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/i18n/datepicker-pt';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui-timepicker-addon';
import 'jquery-ui-timepicker-addon/dist/i18n/jquery-ui-timepicker-pt';
import 'jquery-ui-timepicker-addon/src/jquery-ui-timepicker-addon.css';
import 'remodal';
import 'remodal/dist/remodal.css';
import 'remodal/dist/remodal-default-theme.css';
import 'noty';
import 'jscroll';
import 'select2';
import 'select2/dist/js/i18n/pt';
import 'jquery.are-you-sure';
import 'cookieconsent';
import 'cookieconsent/src/styles/base.css';
import 'django';
import 'evotum';

// internal jquery plugins
import '@app/components/django-messages-noty.jquery';
import '@app/components/file-upload.jquery';
import '@app/components/forms.jquery';
import '@app/components/formsets.jquery';
import '@app/components/modal-partial.jquery';
import '@app/components/noty-evote-theme.jquery';
import '@app/components/scrutiny.jquery';
import '@app/components/secure-actions.jquery';
import '@app/components/shuffle.jquery';
import '@app/components/sortable-init.jquery';
import '@app/components/stickyheader.jquery';
import '@app/components/submit-on-change.jquery';

import { App } from '@app/app';

document.addEventListener('DOMContentLoaded', () => {
  App.bootstrap();
});
