import { Plugin } from '@app/core';
import { NotificationsService } from '@app/services';

/**
 * Open print window on click.
 *
 * @export
 * @class DjangoMessagesPlugin
 * @extends {Plugin}
 */
export class DjangoMessagesPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof DjangoMessagesPlugin
   */
  protected bootstrapOnly = true;

  /**
   * Search for messages defined by django and show them.
   *
   * @memberof DjangoMessagesPlugin
   */
  public applyToElement(element: Element) {
    element.querySelectorAll('li').forEach((message: Element) => {
      NotificationsService.show({
        text: message.innerHTML,
        type: message.className,
      });
    });
  }
}
