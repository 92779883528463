import 'noty';

interface IMessage {
  type: string;
  text: string;
}

/**
 * Service with logic to show messages or notification to end user.
 *
 * @export
 * @class NotificationsService
 */
export class NotificationsService {
  /**
   * Show message using Noty.
   *
   * @private
   * @param {IMessage} message
   * @memberof NotificationsService
   */
  public static show(message: IMessage) {
    noty({
      progressBar: true,
      text: message.text,
      theme: 'evoteTheme',
      layout: 'topCenter',
      timeout: 5000,
      type: message.type,
      animation: {
        open: 'animate__animated animate__fadeIn',
        close: 'animate__animated animate__fadeOut',
      },
    });
  }
}
