import { Router } from './router';

/**
 * Base Script class helper.
 *
 * @export
 * @abstract
 * @class Script
 */
export abstract class Script {
  /**
   * Indicates if script must be executed only on application bootstrap or always.
   *
   * @protected
   * @type {boolean}
   * @memberof Script
   */
  protected bootstrapOnly: boolean = false;

  /**
   * Router that ran this script.
   *
   * @private
   * @type {Router}
   * @memberof Script
   */
  private router?: Router;

  /**
   * Getter for attribute bootstrapOnly
   *
   * @returns {boolean}
   * @memberof Script
   */
  public isBootstrapOnly(): boolean {
    return this.bootstrapOnly;
  }

  /**
   * Setter for attribute router.
   *
   * @param {Router} router
   * @memberof Script
   */
  public setRouter(router: Router) {
    this.router = router;
  }

  /**
   * Getter for attribute router.
   *
   * @returns {(Router | null)}
   * @memberof Script
   */
  public getRouter(): Router | null {
    return this.router || null;
  }

  /**
   * Abstract method to be implemented.
   *
   * @abstract
   * @memberof Script
   */
  public abstract execute(rootElement: ParentNode): void;
}
