import loadGoogleMapsApi from 'load-google-maps-api';

import { Plugin } from '@app/core';

export class GoogleMapsPlugin extends Plugin {
  /**
   * Apply Google Maps after loaded.
   *
   * @memberof GoogleMapsPlugin
   */
  protected applyToElement(element: Element) {
    loadGoogleMapsApi({ key: 'AIzaSyCf-LFa655KFIMFBMJqEb4zy7QuN8ZwFYY' }).then(() => {
      var address = element.getAttribute('data-address');
      var gps = element.getAttribute('data-gps')?.split(',');

      if (typeof address !== 'undefined') {
        new google.maps.Geocoder().geocode({ address: address }, (results, status) => {
          if (results && status == google.maps.GeocoderStatus.OK) {
            location = results[0].geometry.location;
          }
          this.initMap(element, location);
        });
      } else if (gps) {
        var location = new google.maps.LatLng({
          lat: parseFloat(gps[0]),
          lng: parseFloat(gps[1]),
        });
        this.initMap(element, location);
      }
    });
  }

  /**
   * Initiate map and marker.
   *
   * @param element HTML element
   * @param location Lat and Lon of pin location
   * @param zoom Zoom to apply on map
   */
  protected initMap(element: Element, location: google.maps.LatLng, zoom: number = 15) {
    var map = new google.maps.Map(element, {
      zoom: zoom,
      center: location,
    });
    new google.maps.Marker({
      position: location,
      map: map,
    });
  }
}
