export class Settings {
  /**
   * Object with settings.
   *
   * @private
   * @type {{[key: string]: any}}
   * @memberof Settings
   */
  private settings: { [key: string]: any } = {};

  /**
   * Creates an instance of Settings.
   *
   * @param {string} [id='djSettings']
   * @memberof Settings
   */
  constructor(id: string = 'djSettings') {
    const element = document.getElementById(id);

    if (element && element.textContent) {
      this.settings = JSON.parse(element.textContent);
    }
  }

  /**
   * Get setting by name.
   *
   * @param {string} name
   * @returns {*}
   * @memberof Settings
   */
  public get(name: string): any {
    if (this.settings.hasOwnProperty(name)) {
      return this.settings[name];
    }

    return null;
  }
}

export const settings = new Settings();
