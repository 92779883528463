import tinymce from 'tinymce';

import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.css';

// @ts-ignore
import contentCss from 'tinymce/skins/content/default/content.css';

import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/print';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';

import { Script, settings } from '@app/core';

export class TinyMCEScript extends Script {
  /**
   * Apply TinyMCE after loaded.
   *
   * @memberof TinyMCEScript
   */
  public execute() {
    var contentStyleCss = '';

    if (typeof contentCss.toString === 'function') {
      contentStyleCss = contentCss.toString();
    }

    tinymce.init({
      selector: 'textarea[data-tinymce]',
      width: 590,
      height: 300,
      menubar: false,
      skin: false,
      plugins: 'autolink link lists print hr anchor searchreplace wordcount code fullscreen',
      toolbar:
        'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link searchreplace fullscreen',
      content_style: contentStyleCss,
      content_css: [settings.get('TINY_MCE_CSS')],
    });
  }
}
