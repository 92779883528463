import * as Ladda from 'ladda';

import { Plugin } from '@app/core';

export class LaddaPlugin extends Plugin {
  /**
   * Apply Ladda plugin.
   *
   * @memberof LaddaPlugin
   */
  protected applyToElement(element: Element) {
    Ladda.bind(element as HTMLElement);
  }
}
