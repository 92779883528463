import { Plugin } from './plugin';
import { Script } from './script';

export interface IRoute {
  /**
   * Regular expression to be matched. If not provided, view is always called.
   * @type {RegExp}
   */
  match?: RegExp;

  /**
   * Plugins to be applied.
   * @type {Array<Plugin<any>>}
   */
  plugins: Array<Plugin | Script>;
}

export declare type Routes = IRoute[];

/**
 * Router base class.
 *
 * @export
 * @class Router
 */
export class Router {
  /**
   * Defined routes;
   * @private
   * @type {Routes}
   */
  private routes: Routes;

  /**
   * Creates an instance of Router.
   *
   * @param {Routes} routes
   * @memberof Router
   */
  public constructor(routes: Routes) {
    this.routes = routes;
  }

  /**
   * Apply plugins to root element.
   *
   * @private
   * @param {Array<Plugin<any>>} plugins
   * @memberof Router
   */
  public applyPlugins({
    rootElement = document,
    bootstrap = false,
  }: {
    rootElement?: ParentNode;
    bootstrap?: boolean;
  }) {
    this.getMatchPlugins(bootstrap).forEach((plugin: Plugin | Script) => {
      plugin.setRouter(this);
      plugin.execute(rootElement);
    });
  }

  /**
   * Get matching plugins, accordingly to body names and undefined match's.
   *
   * @private
   * @returns {(Array<Plugin | Script>)}
   * @memberof Router
   */
  private getMatchPlugins(bootstrap: boolean): Array<Plugin | Script> {
    let plugins: Array<Plugin | Script> = [];

    const names = this.getRouteNames();
    const concatElements = (route: IRoute) => {
      if (bootstrap) {
        plugins = plugins.concat(route.plugins);
      } else {
        plugins = plugins.concat(route.plugins.filter((plugin: Plugin | Script) => !plugin.isBootstrapOnly()));
      }
    };

    this.routes.forEach((route: IRoute) => {
      if (route.match !== undefined) {
        // need to repeat route.match !== undefined to avoid linter errors.
        names.forEach((name: string) => {
          if (route.match !== undefined && route.match.test(name)) {
            concatElements(route);
          }
        });
      } else {
        concatElements(route);
      }
    });

    return plugins;
  }

  /**
   * Extract body classes, remove view prefix and return array.
   *
   * @private
   * @returns {string[]}
   * @memberof Router
   */
  private getRouteNames(): string[] {
    return document.body.className
      .toLowerCase()
      .split(/\s+/)
      .map((className: string) => className.replace(/^view\-/, ''));
  }
}
