import FontFaceObserver from 'fontfaceobserver';

import { Script } from '@app/core';

/**
 * Loads font assynchronously and add css class to document when finished.
 *
 * @export
 * @class FontLoaderScript
 * @extends {Script}
 */
export class FontLoaderScript extends Script {
  /**
   * Class added to document when finished to load fonts'.
   * @private
   * @static
   * @memberof FontLoaderScript
   */
  private static DOCUMENT_CLASS = 'fonts-loaded';

  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof FontLoaderScript
   */
  protected bootstrapOnly = true;

  /**
   * Execute the load.
   *
   * @memberof FontLoaderScript
   */
  public execute(): void {
    const roboto300 = new FontFaceObserver('Roboto', { weight: 300 });
    const roboto400 = new FontFaceObserver('Roboto', { weight: 400 });
    const roboto600 = new FontFaceObserver('Roboto', { weight: 500 });

    Promise.all([roboto300.load(), roboto400.load(), roboto600.load()]).then(() => {
      document.documentElement.classList.add(FontLoaderScript.DOCUMENT_CLASS);
    });
  }
}
