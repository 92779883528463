import { Plugin, settings } from '@app/core';

export class RecaptchaPlugin extends Plugin {
  /**
   * Creates an instance of RecaptchaPlugin.
   *
   * @param {Element} element
   * @memberof RecaptchaPlugin
   */
  public applyToElement(element: Element) {
    if (settings.get('RECAPTCHA_ACTIVE')) {
      const script = document.createElement('script');

      script.src = settings.get('RECAPTCHA_CLIENT_URL');
      script.async = true;
      script.defer = true;
      script.onload = () => this.onLoad.call(this, element);

      document.body.appendChild(script);
    } else {
      console.warn(
        'Recaptcha is disabled. If this is not intended, please check RECAPTCHA_PUBLIC_KEY or RECAPTCHA_ACTIVE configurations.'
      );
    }
  }

  /**
   * Behavior after recaptcha load.
   *
   * @private
   * @param {Element} recaptchaInput
   * @memberof RecaptchaPlugin
   */
  private onLoad(recaptchaInput: Element) {
    if (recaptchaInput) {
      grecaptcha.ready(() => {
        grecaptcha.execute(settings.get('RECAPTCHA_PUBLIC_KEY'), { action: 'form' }).then((token: any) => {
          recaptchaInput.setAttribute('value', token);
        });
      });
    }
  }
}
